import {
  MessageOutlined as MessageOutlinedIcon,
  Delete as DeleteIcon,
  ThumbUp as ThumbUpIcon,
  Send as SendIcon,
  Launch as LaunchIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Button,
  Pagination,
  Box,
  useTheme,
} from '@mui/material';
import FacebookLikeIcon from '../../../assets/icons/facebook-reaction-like.svg';
import DeleteCommentIcon from '../../../assets/deletecomment.svg';
import FBlikeIcon from '../../../assets/fblike.svg'
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'src/components/EmojiPicker';
import TextIconLabel from 'src/components/TextIconLabel';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import FacebookPostCard from '../publishedPostsPostCard/facebookPostCard.component';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import { useToaster } from 'src/utils/toaster/toasterContext';
import FacebookIcon from '../../../assets/facebook.svg';
import { LoadingButton } from '@mui/lab';
import FacebookPostImagesPreview from 'src/pages/scheduledPost/Preview/FacebookPreview/facebook-post-images-preview.component';

const FacebookPostDetailView = ({ pageId, postId, profile, height }) => {
  const sideBarWidth = 800;
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES
  );
  const fileInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const childCommentInputRef = useRef(null);
  const [postDetails, setPostDetails] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [selectedComments, setSelectedComments] = useState([]);
  const [comment, setComment] = useState('');
  const [childComment, setChildComment] = useState('');
  const [childCommentId, setChildCommentId] = useState('');
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const [loaderComments, setLoaderComments] = useState(false);
  const [loaderReplies, setLoaderReplies] = useState(false);
  const theme = useTheme();
  const fbColor = theme.palette.preview.facebook.color;
  const fbComment = theme.palette.preview.comments.facebook;

  const setCommentToInitialState = () => {
    setComment('');
    setChildComment('');
  };
  useEffect(() => {
    getPostDetails();
  }, []);
  const facebookPostDetailsSuccessCallback = (data, message) => {
    let pDetails = {
      user_name: data.username,
      id: data.id,
      image_url: data.image_url,
      message: data.message,
      like_count: data.like_count,
      comment_count: data.comment_count,
      share_count: data.share_count,
      created_time: data.created_time,
      is_liked: data.is_liked,
      link: data.link,
    };
    setPostDetails(pDetails);
    setCommentData(data.comments_data);
    setPagination(data.comments_data.pagination);
    setLoader(false);
    setLoading(false);
    showToast(message, 's');
  };
  const facebookNextPostSuccessCallback = (data, message) => {
    let updatedComments = [...commentData.comments, ...data.comments_data.comments];
    let pagination = data.comments_data.pagination;
    setCommentData({
      comments: updatedComments,
      pagination: pagination,
    });
    setPagination(data.comments_data.pagination);
    setLoaderComments(false);
    showToast(message, 's');
  };
  const facebookNextReplySuccessCallback = (data, message, commentId) => {
    let updatedComments = commentData.comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          nested_comments: [...comment.nested_comments, ...data.comments_data.comments],
          pagination: data.comments_data.pagination,
        };
      }
      return comment;
    });
    setCommentData({
      comments: updatedComments,
      pagination: commentData.pagination,
    });
    setLoaderReplies(false);
  };
  const facebookPostDetailsFailureCallback = (message) => {
    setPostDetails(null);
    setCommentData(null);
    showToast(message, 'e');
    setLoader(false);
    setLoading(false);
    setLoaderComments(false);
    setLoaderReplies(false);
  };
  const getPostDetails = () => {
    setLoader(true);
    let getProps = {
      url: `${postsModuleURL.getFacebookPostDetails}${pageId}&post_id=${postId}`,
      successCallback: facebookPostDetailsSuccessCallback,
      failureCallback: facebookPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextComments = () => {
    setLoaderComments(true);
    let getProps = {
      url: `${postsModuleURL.getFacebookNextComments}${pageId}&post_id=${postId}&after=${pagination}`,
      successCallback: facebookNextPostSuccessCallback,
      failureCallback: facebookPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextReplies = (comment) => {
    setLoaderReplies(true);
    const commentId = comment.id;
    let getProps = {
      url: `${postsModuleURL.getFacebookNextReply}${pageId}&post_id=${commentId}&after=${comment.pagination}`,
      successCallback: (data, message) => facebookNextReplySuccessCallback(data, message, commentId),
      failureCallback: facebookPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const handleChangeComment = (e, isParent) => {
    if (isParent) setComment(e.target.value);
    else setChildComment(e.target.value);
  };
  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };
  const handleFocusComment = () => {
    commentInputRef.current?.focus();
  };
  const handleFocusChildComment = () => {
    childCommentInputRef.current?.focus();
  };
  const handleLikePost = (post) => {
    let bodyProps = {
      id: postDetails.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.likeFacebookPost,
      body: bodyProps,
      failureCallback,
    };
    postDetails.is_liked = true;
    postDetails.like_count = postDetails.like_count + 1;
    setPostDetails({ ...postDetails });
    HttpServices.Post(postProps);
  };
  const handleUnLikePost = () => {
    let bodyProps = {
      id: postDetails.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.unlikeFacebookPost,
      body: bodyProps,
      failureCallback,
    };
    postDetails.is_liked = false;
    if (postDetails.like_count > 0) postDetails.like_count = postDetails.like_count - 1;
    setPostDetails({ ...postDetails });
    HttpServices.Post(postProps);
  };
  const failureCallback = (message) => {
    postDetails.is_liked = !postDetails.is_liked;
    if (postDetails.is_liked) postDetails.like_count = postDetails.like_count + 1;
    if (postDetails.like_count > 0 && !postDetails.is_liked) postDetails.like_count = postDetails.like_count - 1;
    setPostDetails({ ...postDetails });
    message && showToast(message, 'e');
  };
  const postContent = () => {
    if (postDetails) {
      let cardDetails = {
        media_url: postDetails.image_url,
        message: postDetails.message,
        like_count: postDetails.like_count,
        comment_count: postDetails.comment_count,
        share_count: postDetails.share_count,
        is_liked: postDetails.is_liked,
        profile,
        handleLikePost,
        handleUnLikePost,
        handleFocusComment,
      };
      let data ={
        images: [postDetails.image_url],
        message: postDetails.message,
      }
      return <FacebookPostCard {...cardDetails} />;
    } else return null;
  };
  const postComments = () => {
    return (
      <Stack spacing={1.5} sx={{ mt: 5, mb: 15 }}>
        {commentData &&
          commentData.comments &&
          commentData.comments.length > 0 &&
          commentData.comments.map((comment) => {
            return (
              <>
                <Card sx={{ m: '10px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                  {commentView(comment)}
                </Card>
                {selectedComments.some((a) => a.id === comment.id) &&
                  comment.nested_comments &&
                  comment.nested_comments.length > 0 && (
                    <>
                      {comment.nested_comments.map((nestedComment) => (
                        <Card
                          key={nestedComment.id}
                          sx={{ marginLeft: '80px !important', marginRight: '12rem !important', boxShadow: 'none' }}
                        >
                          {commentView(nestedComment, comment)}
                        </Card>
                      ))}
                      {comment.pagination !== '' && (
                        <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
                          {loaderReplies ? (
                            <ThreeDotLoader />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 700,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: theme.palette.grey[500],
                              }}
                              onClick={() => getNextReplies(comment)}
                            >
                              Next Replies
                            </Typography>
                          )}
                        </Box>
                      )}
                    </>
                  )}

                {childCommentId && comment.id === childCommentId && writeContentView(childCommentId, childComment)}
              </>
            );
          })}
        {pagination && commentData.comments.length > 9 && (
          <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
            {loaderComments ? (
              <ThreeDotLoader />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: theme.palette.grey[500],
                }}
                onClick={getNextComments}
              >
                Load More Comments
              </Typography>
            )}
          </Box>
        )}
      </Stack>
    );
  };

  const handleClickComment = (comment, parentComment) => {
    if (parentComment) {
      setChildCommentId(parentComment.id);
    } else {
      if (comment.comment_count === 0) {
        setChildCommentId(comment.id);
      } else {
        if (selectedComments.find((c) => c.id === comment.id)) {
          setChildCommentId(comment.id);
        }
        setSelectedComments([comment]);
        handleFocusComment();
      }
    }
  };

  const facebookLikeFailureCallback = (data, message, selectedComment, parentComment) => {
    if (message) {
      showToast(message, "e");
    }
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      let childComment = parentData.nested_comments.find((a) => a.id === selectedComment.id);
      if (childComment) {
        childComment.is_liked = false;
        if (childComment.like_count > 0) childComment.like_count = childComment.like_count - 1;
      }
    } else {
      let pComment = commentData.comments.find((a) => a.id === selectedComment.id);
      if (pComment) {
        pComment.is_liked = false;
        if (pComment.like_count > 0) pComment.like_count = pComment.like_count - 1;
      }
    }
    setCommentData({ ...commentData });
  };

  const facebookUnlikeFailureCallback = (message, selectedComment, parentComment) => {
    if (message) {
      showToast(message, "e");
    }
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      let childComment = parentData.nested_comments.find((a) => a.id === selectedComment.id);
      if (childComment) {
        childComment.is_liked = true;
        childComment.like_count = childComment.like_count + 1;
      }
    } else {
      let pComment = commentData.comments.find((a) => a.id === selectedComment.id);
      if (pComment) {
        pComment.is_liked = true;
        pComment.like_count = pComment.like_count + 1;
      }
    }
    setCommentData({ ...commentData });
  };

  const handleLikeComment = (selectedComment, parentComment) => {
    let bodyProps = {
      id: selectedComment.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.likeFacebookPost,
      body: bodyProps,
      failureCallback: (data, message) => facebookLikeFailureCallback(data, message, selectedComment, parentComment),
    };
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      let childComment = parentData.nested_comments.find((a) => a.id === selectedComment.id);
      if (childComment) {
        childComment.is_liked = true;
        childComment.like_count = childComment.like_count + 1;
      }
    } else {
      let pComment = commentData.comments.find((a) => a.id === selectedComment.id);
      if (pComment) {
        pComment.is_liked = true;
        pComment.like_count = pComment.like_count + 1;
      }
    }
    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const handleUnLikeComment = (selectedComment, parentComment) => {
    let bodyProps = {
      id: selectedComment.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.unlikeFacebookPost,
      body: bodyProps,
      failureCallback: (message) => facebookUnlikeFailureCallback(message, selectedComment, parentComment),
    };
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      let childComment = parentData.nested_comments.find((a) => a.id === selectedComment.id);
      if (childComment) {
        childComment.is_liked = false;
        if (childComment.like_count > 0) childComment.like_count = childComment.like_count - 1;
      }
    } else {
      let pComment = commentData.comments.find((a) => a.id === selectedComment.id);
      if (pComment) {
        pComment.is_liked = false;
        if (pComment.like_count > 0) pComment.like_count = pComment.like_count - 1;
      }
    }
    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const commentView = (comment, parentComment) => {
    return (
      <>
        <Stack key={comment.id} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15 }}>
          {parentComment && <Divider orientation="vertical" flexItem sx={{ borderLeftWidth: 2 }} />}
          <Avatar alt={comment.username} src={comment.profile_url}  sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: 40,
            width: 40,
            bgcolor: (theme) => theme.palette.primary.main,
          }}/>
          <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', boxShadow: 3, borderRadius: '10px' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
              <Typography variant="subtitle2">{comment.username}</Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {comment.text}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                {CommonHelper.DateTimeFromCurrentTime(comment.created_time)}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                  color: 'text.disabled',
                }}
              >
                <StyledIconButton
                  onClick={() => {
                    hasManageLikesCommentPermission && (comment.is_liked ?  handleUnLikeComment(comment, parentComment) : handleLikeComment(comment, parentComment));
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.like_count ? comment.like_count : ''}
                    sx={{ typography: 'subtitle2', }}
                    icon={
                      comment.is_liked ? (
                        <img src={FacebookLikeIcon} style={{ width: 18, height: 18 }} />
                      ) : (
                        <img src={FBlikeIcon} style={{ width: 18, height: 18 }} />
                      )
                    }
                  />
                </StyledIconButton>
                <StyledIconButton
                  onClick={() => {
                    handleClickComment(comment, parentComment);
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.comment_count ? comment.comment_count : ''}
                    sx={{ typography: 'subtitle2' }}
                    icon={
                      <MessageOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          color: comment.comment_count ? fbComment : 'theme.pallete.GREY[500]',
                        }}
                      />
                    }
                  />
                </StyledIconButton>
                <StyledIconButton
                  disabled={!hasManageLikesCommentPermission}
                  onClick={() => {
                    handleDeleteComment(comment, parentComment);
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    sx={{ typography: 'subtitle2' }}
                    icon={<img src={DeleteCommentIcon} style={{ width: 20, height: 20 }} />}
                  />
                </StyledIconButton>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </>
    );
  };

  const handleDeleteComment = (comment, parentComment) => {
    let deleteBody = {
      id: comment.id,
      connected_profile_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.deleteFacebookCommentAndMessage,
      body: deleteBody,
      failureCallback: (message) => facebookDeleteFailureCallback(message, comment, parentComment),
    };
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      let index = parentData.nested_comments.findIndex((a) => a.id === comment.id);
      parentData.nested_comments.splice(index, 1);
      parentData.comment_count -= 1;
    } else {
      let index = commentData.comments.findIndex((a) => a.id === comment.id);
      commentData.comments.splice(index, 1);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count - 1,
      }));
    }
    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const handleEmoji = (emoji) => {
    setComment(comment + emoji);
  };

  const facebookDeleteSuccessCallback = (data, message) => {
    showToast(message, 's');
  };

  const facebookDeleteFailureCallback = (message, deletedComment, parentComment) => {
    message && showToast(message, 'e');
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      parentData.nested_comments.push(deletedComment);
      parentData.comment_count += 1;
    } else {
      commentData.comments.push(deletedComment);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count + 1,
      }));
    }
    setCommentData({ ...commentData });
  };

  const writeContentView = (id, comment, isParent = false) => {
    return (
      <Card
        sx={{
          ...(!isParent && { boxShadow: 'none' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            ...(isParent && {
              position: 'fixed',
              bottom: '0px',
              padding: '25px',
              width: sideBarWidth,
              background: 'white',
            }),
          }}
          sx={{ marginRight: '12rem !important', marginLeft: isParent ? '0px' : '80px !important' }}
        >
          <Avatar
            src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
            alt={profile.profile_name ? profile.profile_name : profile.id}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: 40,
              width: 40,
              bgcolor: (theme) => theme.palette.primary.main,
            }}
            style={{ color: 'white' }}
          />
          <TextField
            fullWidth
            size="small"
            value={comment}
            onKeyUp={(e)=>{
              handleKeyUp(e,comment,isParent)}
            }
            inputRef={isParent ? commentInputRef : childCommentInputRef}
            placeholder="Write a comment…"
            disabled={!hasManageLikesCommentPermission}
            onChange={(e) => {
              handleChangeComment(e, isParent);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  {isParent ? <EmojiPicker handleEmoji={handleEmoji} /> : <></>}
                </InputAdornment>
              ),
            }}

            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
              },
              opacity: !hasManageLikesCommentPermission ? 0.5 : 1,
            }}
          />
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            endIcon={<SendIcon />}
            size=""
            onClick={() => {
              handlePostComment(comment, isParent);
            }}
            disabled={!comment || !hasManageLikesCommentPermission}
            sx={{
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
                opacity: 0.6,
              },
              '&.MuiLoadingButton-root:hover': {
                border: 'none',
                background: 'none',
              },
            }}
          ></LoadingButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Card>
    );
  };
  const handleKeyUp = (event,comment,isParent) => {
    if (event.key === 'Enter' && comment !== "" && hasManageLikesCommentPermission) {
      handlePostComment(comment,isParent);
    }
  };
  const handlePostComment = (comment, isParent) => {
    setLoading(true);
    let postCommentBody = {
      connected_profile_id: pageId,
      id: isParent ? postId : childCommentId,
      message: comment,
    };

    let postProps = {
      url: postsModuleURL.addFacebookComment,
      body: postCommentBody,
      successCallback: (data, message) => getAfterAddCommentPostDetails(data, message, isParent ,comment),
      failureCallback: (message) => facebookPostFailureCallback(message),
    };

    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const getAfterAddCommentPostDetails = (data, message, isParent, text) => {
    if (isParent) {
      // Adding a new parent comment
      commentData.comments.push({
        id: data.id,
        text: text,
        is_liked: false,
        like_count: 0,
        comment_count: 0,
        created_time: new Date().toISOString(),
        profile_url: profile?.profile_image,
        username: profile?.profile_name,
        nested_comments: []
      });
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count + 1,
      }));
    } else {
      // Adding a new child comment
      let parentComment = commentData.comments.find((c) => c.id === childCommentId);
      if (parentComment) {
        parentComment.nested_comments.push({
          id: data.id,
          text: text,
          is_liked: false,
          created_time: new Date().toISOString(),
          profile_url: profile?.profile_image,
          username: profile?.profile_name,
          like_count: 0
        });
        parentComment.comment_count += 1;
      }
    }
    setCommentData({ ...commentData });
    setLoading(false);
    showToast(message, 's');
    setCommentToInitialState()
  };

  const facebookPostFailureCallback = (message) => {
    setLoading(false);
    message && showToast(message, 'e');
  };

  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Box sx={{ position: 'fixed', width: sideBarWidth, zIndex: 1000, background: 'white' }}>
          <Grid alignItems="center" display="flex" sx={{ pt: 1, pb: 1 }}>
            <img alt="Facebook_Icon" width={100} height={50} sx={{ fontSize: 22 }} src={FacebookIcon} />
            <Typography color={fbColor} style={{ fontSize: 40, fontWeight: 800 }}>
              Facebook
            </Typography>
            <a href={postDetails?.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
              <LaunchIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
            </a>
          </Grid>
          <Divider />
        </Box>
        <Box sx={{ mt: '5.6rem' }}>
          {showLoader ? (
            <Spinner height={height} />
          ) : (
            <>
              <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
                {postContent()}
              </Grid>
              {postComments()}
              {writeContentView(pageId, comment, true)}
            </>
          )}
        </Box>
      </StyledComponent.StyledStack>
    </>
  );
};

//Styled Components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px', // for screens wider than 600px
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '24px', // for screens wider than 960px
    },
  },
}));

export default FacebookPostDetailView;
