import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Button, Box, Typography, useTheme, TextField } from '@mui/material';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import PinterestCreateBoard from './pinterest-create-board';

const GetPhoneNumber = ({ profileId, profile, handleActionChange }) => {
    const theme = useTheme();
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [selectedBoardId, setSelectedBoardId] = useState('');
    const [pinterestCreateBoardOpen, setPinterestCreateBoardOpen] = useState(false);

    // Fetch Boards and set the first one as selected
    // const successCallback = (data, message) => {
    //     setBoards(data);
    //     if (data.length > 0) {
    //         const firstBoardId = data[0].board_id;
    //         setSelectedBoardId(firstBoardId);
    //         handleChangeBoard(profileId, profile, firstBoardId);
    //     }
    // };

    // const failureCallback = (error) => {
    //     console.error('Error fetching boards:', error);
    // };

    useEffect(() => {
        const getProps = {
            url: `${integrateModuleURL.getPhoneNumber}${profileId}`,
            successCallback,
            failureCallback,
        };
        HttpServices.Get(getProps);
    }, [profileId]);
    const successCallback = (data, message) => {
        setPhoneNumber(data.phone_number);
    }
    const failureCallback = (error) => {
        console.error('Error fetching phone number:', error);
    };




    return (
        <>
            <FormControl sx={{ minWidth: 120 }}>
                <Select
                    value={profile.button_type}
                    onChange={(event) => {
                        handleActionChange(profileId, profile, event.target.value);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
                >
                    <MenuItem value={'None'}>None</MenuItem>
                    <MenuItem value={'Book'}>Book</MenuItem>
                    <MenuItem value={'Order Online'}>Order Online</MenuItem>
                    <MenuItem value={'Buy'}>Buy</MenuItem>
                    <MenuItem value={'Learn More'}>Learn More</MenuItem>
                    <MenuItem value={'Sign Up'}>Sign Up</MenuItem>
                    {phoneNumber != '' && (
                        <MenuItem value={"CALL"}  >
                            Call Now
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

        </>
    );
};

export default GetPhoneNumber;
