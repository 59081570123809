import {
  MessageOutlined as MessageOutlinedIcon,
  Delete as DeleteIcon,
  ThumbUp as ThumbUpIcon,
  Send as SendIcon,
  Launch as LaunchIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'src/components/EmojiPicker';
import TextIconLabel from 'src/components/TextIconLabel';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import InstagramPostCard from '../publishedPostsPostCard/instagramPostCard.component';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import ThreeDotLoader from 'src/utils/Loader/threeDotLoader.compontent';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Instagram from '../../../assets/instagram.svg';
import InstagramLikeIcon from '../../../assets/icons/instagram-comment-like.svg';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import DeleteCommentIcon from '../../../assets/deletecomment.svg';
import { LoadingButton } from '@mui/lab';

const InstagramPostDetailView = ({ pageId, postId, profile, height }) => {
  const sideBarWidth = 800;
  const fileInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const childCommentInputRef = useRef(null);
  const [postDetails, setPostDetails] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [selectedComments, setSelectedComments] = useState([]);
  const [comment, setComment] = useState('');
  const [childComment, setChildComment] = useState('');
  const [childCommentId, setChildCommentId] = useState('');
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState('');
  const [loaderComments, setLoaderComments] = useState(false);
  const [loaderReplies, setLoaderReplies] = useState(false);
  const theme = useTheme();
  let instagramLike = theme.palette.preview.instagram.likes;
  let instagramComment = theme.palette.preview.comments.instagram;
  const hasManageLikesCommentPermission = CommonHelper.CheckForPermissionAvailableForUser(
    CommonHelper.PermissionList.MANAGE_COMMENTS_LIKES
  );
  const setCommentToInitialState = () => {
    setComment('');
    setChildComment('');
  };
  useEffect(() => {
    getPostDetails();
  }, []);
  const instagramPostDetailsSuccessCallback = (data, message) => {
    let pDetails = {
      id: data.id,
      media_url: data.image_url || data.thumbnail_url,
      media_type: data.media_type,
      message: data.message,
      like_count: data.like_count,
      comment_count: data.comment_count,
      share_count: data.share_count,
      created_time: data.created_time,
      link: data.link,
    };
    setPostDetails(pDetails);
    setCommentData(data.comments_data);
    setPagination(data.pagination);
    setLoader(false);
    setLoading(false);
  };
  const instagramNextPostSuccessCallback = (data, message) => {
    let updatedComments = [...commentData.comments, ...data.comments_data.comments];
    let pagination = data.comments_data.pagination;
    setCommentData({
      comments: updatedComments,
      pagination: pagination,
    });
    setPagination(data.comments_data.pagination);
    setLoaderComments(false);
    showToast(message, 's');
  };
  const instagramNextReplySuccessCallback = (data, message, commentId) => {
    let updatedComments = commentData.comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [...comment.replies, ...data.replies],
          pagination: data.pagination,
        };
      }
      return comment;
    });
    setCommentData({
      comments: updatedComments,
      pagination: commentData.pagination,
    });
    setLoaderReplies(false);
  };
  const instagramPostDetailsFailureCallback = (message) => {
    setPostDetails(null);
    setCommentData(null);
    showToast(message, 'e');
    setLoader(false);
    setLoading(false);
    setLoaderComments(false);
    setLoaderReplies(false);
  };
  const getPostDetails = () => {
    setLoader(true);
    let getProps = {
      url: `${postsModuleURL.getIntagramPostDetails}${pageId}&post_id=${postId}`,
      successCallback: instagramPostDetailsSuccessCallback,
      failureCallback: instagramPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const handleChangeComment = (e, isParent) => {
    if (isParent) setComment(e.target.value);
    else setChildComment(e.target.value);
  };
  const handleClickAttach = () => {
    fileInputRef.current?.click();
  };
  const handleFocusComment = () => {
    commentInputRef.current?.focus();
  };
  const handleFocusChildComment = () => {
    childCommentInputRef.current?.focus();
  };
  const handleKeyUp = (event, comment, isParent) => {
    if (event.key === 'Enter' && comment !== "" && hasManageLikesCommentPermission) {
      handlePostComment(comment, isParent);
    }
  };
  const getNextComments = () => {
    setLoaderComments(true);
    let getProps = {
      url: `${postsModuleURL.getInstagramNextComments}${pageId}&post_id=${postId}&after=${pagination}`,
      successCallback: instagramNextPostSuccessCallback,
      failureCallback: instagramPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const getNextReplies = (comment) => {
    setLoaderReplies(true);
    const commentId = comment.id;
    let getProps = {
      url: `${postsModuleURL.getInstagramNextReply}${pageId}&post_id=${commentId}&after=${comment.pagination}`,
      successCallback: (data, message) => instagramNextReplySuccessCallback(data, message, commentId),
      failureCallback: instagramPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };
  const postContent = () => {
    if (postDetails) {
      let cardDetails = {
        media_url: postDetails.media_url,
        media_type: postDetails.media_type,
        description: postDetails.message,
        like_count: postDetails.like_count,
        comment_count: postDetails.comment_count,
        share_count: postDetails.share_count,
        is_liked: postDetails.is_liked,
        profile,
        handleFocusComment,
      };
      return <InstagramPostCard {...cardDetails} />;
    } else return null;
  };
  const postComments = () => {
    return (
      <Stack spacing={1.5} sx={{ mt: 5, mb: 15 }}>
        {commentData &&
          commentData.comments &&
          commentData.comments.length > 0 &&
          commentData.comments.map((comment) => {
            return (
              <>
                <Card sx={{ m: '10px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                  {commentView(comment)}
                </Card>
                {selectedComments.some((a) => a.id === comment.id) && comment.replies && comment.replies.length > 0 && (
                  <>
                    {comment.replies.map((nestedComment) => (
                      <Card sx={{ marginLeft: '80px !important', marginRight: '12rem !important', boxShadow: 'none' }}>
                        {commentView(nestedComment, comment)}
                      </Card>
                    ))}
                    {comment.pagination && (
                      <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
                        {loaderReplies ? (
                          <ThreeDotLoader />
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              color: theme.palette.grey[500],
                            }}
                            onClick={() => getNextReplies(comment)}
                          >
                            Next Replies
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
                {childCommentId && comment.id === childCommentId && writeContentView(childCommentId, childComment)}
              </>
            );
          })}
        {pagination && (
          <Box sx={{ textAlign: 'center', marginRight: '12rem !important' }}>
            {loaderComments ? (
              <ThreeDotLoader />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: theme.palette.grey[500],
                }}
                onClick={getNextComments}
              >
                Load More Comments
              </Typography>
            )}
          </Box>
        )}
      </Stack>
    );
  };

  const handleClickComment = (comment, parentComment) => {
    if (parentComment) {
      setChildCommentId(parentComment.id);
    } else {
      if (comment.comment_count === 0) {
        setChildCommentId(comment.id);
      } else {
        if (selectedComments.find((c) => c.id === comment.id)) {
          setChildCommentId(comment.id);
        }
        setSelectedComments([comment]);
        handleFocusComment();
      }
    }
  };

  const commentView = (comment, parentComment) => {
    return (
      <>
        <Stack key={comment.id} direction="row" spacing={2} style={{ marginLeft: 15, marginRight: 15 }}>
          {parentComment && <Divider orientation="vertical" flexItem sx={{ borderLeftWidth: 2 }} />}
          <Avatar
            alt={comment.profile_url ? comment.profile_url : comment.username}
            src={comment.profile_url ? comment.profile_url : comment.username}
            sx={{ bgcolor: theme.palette.primary.main }}
          />
          <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', boxShadow: 3, borderRadius: '10px' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between">
              <Typography variant="subtitle2">{comment.username}</Typography>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {comment.text}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                {CommonHelper.DateTimeFromCurrentTime(comment.timestamp)}
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                  color: 'text.disabled',
                }}
              >
                <StyledIconButton>
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.like_count ? comment.like_count : ''}
                    sx={{ typography: 'subtitle2' }}
                    icon={comment.like_count > 0 ? <FavoriteOutlinedIcon sx={{ width: 20, height: 20, color: '#ff3040' }} /> : <FavoriteBorderOutlinedIcon sx={{ width: 20, height: 20 }} />}
                  />
                </StyledIconButton>
                <StyledIconButton
                  onClick={() => {
                    handleClickComment(comment, parentComment);
                  }}

                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    value={comment.comment_count ? comment.comment_count : ''}
                    sx={{ typography: 'subtitle2' }}
                    icon={
                      <MessageOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          color: comment.comment_count ? instagramComment : 'theme.pallete.GREY[500]',
                        }}
                      />
                    }
                  />
                </StyledIconButton>
                <StyledIconButton
                  disabled={!hasManageLikesCommentPermission}
                  onClick={() => {
                    handleDeleteComment(comment, parentComment);
                  }}
                >
                  <TextIconLabel
                    aria-label="chatbubbleoutline"
                    sx={{ typography: 'subtitle2' }}
                    icon={<img src={DeleteCommentIcon} style={{ width: 20, height: 20 }} />}
                  />
                </StyledIconButton>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </>
    );
  };

  const handleDeleteComment = (comment, parentComment) => {
    let deleteBody = {
      id: comment.id,
      page_id: pageId,
    };
    let postProps = {
      url: postsModuleURL.deleteInstagramCommentAndMessage,
      body: deleteBody,
      failureCallback: (message) => instagramDeleteFailureCallback(message, comment, parentComment),
    };
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      if (parentData) {
        let index = parentData.replies.findIndex((a) => a.id === comment.id);
        if (index !== -1) {
          parentData.replies.splice(index, 1);
          parentData.comment_count -= 1;
        }
      }
    } else {
      let index = commentData.comments.findIndex((a) => a.id === comment.id);
      if (index !== -1) {
        commentData.comments.splice(index, 1);
      }
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count - 1,
      }));
    };
    setCommentData({ ...commentData });
    HttpServices.Post(postProps);
  };

  const handleEmoji = (emoji) => {
    setComment(comment + emoji);
  };

  const instagramDeleteSuccessCallback = (data, message) => {
    showToast(message);
  };

  const instagramDeleteFailureCallback = (message, deletedComment, parentComment) => {

    message && showToast(message, 'e');
    if (parentComment) {
      let parentData = commentData.comments.find((a) => a.id === parentComment.id);
      if (parentData) {
        parentData.replies.push(deletedComment);
        parentData.comment_count += 1;
      }
    } else {
      commentData.comments.push(deletedComment);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        comment_count: prevDetails.comment_count + 1,
      }));
    }
    setCommentData({ ...commentData });
  };

  const writeContentView = (id, comment, isParent = false) => {
    return (
      <Card
        sx={{
          ...(!isParent && { boxShadow: 'none' }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            ...(isParent && {
              position: 'fixed',
              bottom: '0px',
              padding: '25px',
              width: sideBarWidth,
              background: 'white',
            }),
          }}
          sx={{ marginRight: '12rem !important', marginLeft: isParent ? '0px' : '80px !important' }}
        >
          <Avatar
            src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
            alt={profile.profile_name ? profile.profile_name : profile.id}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: 40,
              width: 40,
              bgcolor: (theme) => theme.palette.primary.main,
            }}
            style={{ color: 'white' }}
          />
          <TextField
            fullWidth
            size="small"
            value={comment}
            disabled={!hasManageLikesCommentPermission}
            onKeyUp={(e) => {
              handleKeyUp(e, comment, isParent)
            }
            }
            inputRef={isParent ? commentInputRef : childCommentInputRef}
            placeholder="Write a comment…"
            onChange={(e) => {
              handleChangeComment(e, isParent);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isParent ? <EmojiPicker handleEmoji={handleEmoji} /> : <></>}
                </InputAdornment>
              ),
            }}
            sx={{
              ml: 2,
              mr: 1,
              opacity: !hasManageLikesCommentPermission ? 0.5 : 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
              },
            }}
          />
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            endIcon={<SendIcon />}
            size=""
            onClick={() => {
              handlePostComment(comment, isParent);
            }}
            disabled={!comment || !hasManageLikesCommentPermission}
            sx={{
              border: 'none',
              '&.Mui-disabled': {
                border: 'none',
                opacity: 0.6,
              },
              '&.MuiLoadingButton-root:hover': {
                border: 'none',
                background: 'none',
              },
            }}
          ></LoadingButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Card>
    );
  };

  const handlePostComment = (comment, isParent) => {
    setLoading(true);
    let postReplyCommentBody = {
      page_id: pageId,
      comment_id: childCommentId,
      message: comment,
    };
    let postCommentBody = {
      page_id: pageId,
      post_id: postId,
      message: comment,
    };
    let postProps = {
      url: isParent ? postsModuleURL.addInstagramComment : postsModuleURL.addInstagramRepliesComment,
      body: isParent ? postCommentBody : postReplyCommentBody,
      successCallback: getAfterAddCommentPostDetails,
      failureCallback: instagramPostFailureCallback,
    };
    HttpServices.Post(postProps);
  };
  const getAfterAddCommentPostDetails = (data, message) => {
    let getProps = {
      url: `${postsModuleURL.getIntagramPostDetails}${pageId}&post_id=${postId}`,
      successCallback: instagramPostDetailsSuccessCallback,
      failureCallback: instagramPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
    showToast(message, 's');
    setCommentToInitialState();
  };
  const instagramPostFailureCallback = (message) => {
    message && showToast(message);
    setLoading(false);
  };
  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Box sx={{ position: 'fixed', width: sideBarWidth, zIndex: 1000, background: 'white' }}>
          <Grid alignItems="center" display="flex" sx={{ pt: 1, pb: 1 }}>
            <img alt="Facebook_Icon" width={100} height={50} sx={{ fontSize: 22, color: '#0866ff' }} src={Instagram} />
            <Typography
              color="#0866ff"
              style={{
                background: 'linear-gradient(to right, #f58529, #dd2a7b, #c13584, #833ab4, #5851db, #405de6)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: 40,
                fontWeight: 800,
              }}
            >
              Instagram
            </Typography>
            <a href={postDetails?.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
              <LaunchIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
            </a>
          </Grid>
          <Divider />
        </Box>
        <Box sx={{ mt: '5.6rem' }}>
          {showLoader ? (
            <Spinner height={height} />
          ) : (
            <>
              <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
                {postContent()}
              </Grid>
              {postComments()}
              {writeContentView(pageId, comment, true)}
            </>
          )}
        </Box>
      </StyledComponent.StyledStack>
    </>
  );
};

//Styled Components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px', // for screens wider than 600px
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '24px', // for screens wider than 960px
    },
  },
}));

export default InstagramPostDetailView;
