import {
  MessageOutlined as MessageOutlinedIcon,
  Delete as DeleteIcon,
  ThumbUp as ThumbUpIcon,
  Send as SendIcon,
  Launch as LaunchIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import EmojiPicker from 'src/components/EmojiPicker';
import TextIconLabel from 'src/components/TextIconLabel';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Pinterest_Icon from '../../../assets/pinterest-icon.svg';
import PinterestPostCard from '../publishedPostsPostCard/pinterestPostCard.component';

const PinterestPostDetailView = ({ pageId, postId, profile, height }) => {
  const sideBarWidth = 800;
  const [postDetails, setPostDetails] = useState(null);
  const { showToast } = useToaster();
  const [showLoader, setLoader] = useState(true);
  useEffect(() => {
    getPostDetails();
  }, []);

  const pinterestPostDetailsSuccessCallback = (data, message) => {
    let pDetails = {
      id: data.id,
      image_url: data.image_url,
      description: data.description,
      title: data.title,
      board_id: data.board_id,
      created_time: data.created_time,
      link: data.link,
    };
    setPostDetails(pDetails);
    setLoader(false);
  };

  const pinterestPostDetailsFailureCallback = (message) => {
    setPostDetails(null);
    showToast(message, 'e');
    setLoader(false);

  };
  const getPostDetails = () => {
    setLoader(true);
    let getProps = {
      url: `${postsModuleURL.getPinterestPostDetails}${pageId}&post_id=${postId}`,
      successCallback: pinterestPostDetailsSuccessCallback,
      failureCallback: pinterestPostDetailsFailureCallback,
    };
    HttpServices.Get(getProps);
  };

  const postContent = () => {
    if (postDetails) {
      let cardDetails = {
        media_url: postDetails.image_url,
        description: postDetails.description,
        title: postDetails.title,
        board_id: postDetails.board_id,
        profile,
      };
      return <PinterestPostCard {...cardDetails} />;
    } else return null;
  };
  return (
    <>
      <StyledComponent.StyledStack sx={{ width: sideBarWidth }}>
        <Grid alignItems="center" display="flex">
          <img alt="Gbusiness_Icon" width={100} height={50} sx={{ fontSize: 22 }} src={Pinterest_Icon} />
          <Typography style={{ fontSize: 40, fontWeight: 800 }}>Pinterest</Typography>
          <a href={postDetails?.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
            <LaunchIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
          </a>
        </Grid>
        <Divider sx={{ mb: 5 }} />
        <Box>
          {showLoader ? (
            <Spinner height={height} />
          ) : (
            <>
              <Grid display="flex" alignItems="flex-start" justifyContent="flex-start" sx={{ ml: '1.5rem !important' }}>
                {postContent()}
              </Grid>
            </>
          )}
        </Box>
      </StyledComponent.StyledStack>
    </>
  );
};


export default PinterestPostDetailView;
